@keyframes useFade-fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes useFade-fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
