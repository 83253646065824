/* stylelint-disable declaration-block-no-duplicate-properties */
/* stylelint-disable string-no-newline */

@use "@styles/helpers/variables" as *;
@use "@styles/helpers/functions" as *;

@mixin pos-left($offset: 0,  $position: absolute) {
    position: $position;
    left: $offset;
    top: 0;
    bottom: 0;
}

@mixin pos-right($offset: 0,  $position: absolute) {
    position: $position;
    right: $offset;
    top: 0;
    bottom: 0;
}

@mixin pos-top($offset: 0,  $position: absolute) {
    position: $position;
    top: $offset;
    left: 0;
    right: 0;
}

@mixin pos-bottom($offset: 0,  $position: absolute) {
    position: $position;
    bottom: $offset;
    left: 0;
    right: 0;
}

@mixin pos-fill($offset: 0, $position: absolute) {
    position: $position;
    inset: $offset $offset $offset $offset;
}

@mixin flex-center($type : flex) {
    display: $type;
    justify-content: center;
    align-items: center;
}

@mixin flex($alignItems: flex-start, $justifyContent: flex-start, $flex-direction: row, $display: flex) {
    display: $display;
    align-items: $alignItems;
    justify-content: $justifyContent;
    flex-direction: $flex-direction;
}

@mixin button-reset {
    border: 0;
    background: transparent;
    outline: 0;
    box-shadow: none;
}

@mixin visible($display: null) {
    visibility: visible;
    opacity: 1;

    @if $display {
        display: $display;
    }
}

@mixin hidden($display: null) {
    visibility: hidden;
    opacity: 0;

    @if $display {
        display: none;
    }
}

@mixin content-fill($height: 100%, $width: 100%) {
    height: $height;
    width: $width;
}

@mixin rounded-corners($radius: 8px) {
    border-radius: $radius;
}

@mixin truncate-text($maxWidth: 120px) {
    max-width: $maxWidth;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// To be replaced with "with-hocus" mixin when we can test the behaviour in the rest of the components
@mixin with-hover() {
    @media screen and (hover:hover) {
        &:hover {
            @content;
        }
    }
}

@mixin with-hocus($hover: true, $focus: true) {
    @media screen and (hover:hover) {
        @if $hover == true and $focus == true {
            &:hover, &:focus {
                @content;
            }
        } @else if $hover == true {
            &:hover {
                @content;
            }
        } @else if $focus == true {
            &:focus {
                @content;
            }
        }
    }
}

@mixin breakpoint($breakpoint, $direction: min) {

    @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint-values: map-get($breakpoints, $breakpoint);
        $breakpoint-min: map-get($breakpoint-values, min);
        $breakpoint-max: map-get($breakpoint-values, max);

        @if $direction == min {
            @media (min-width: $breakpoint-min) {
                @content;
            }
        } @else {
            @media (max-width: $breakpoint-max) {
                @content;
            }
        }
    } @else {
        @if $direction == min {
            @media (min-width: $breakpoint) {
                @content;
            }
        } @else {
            @media (max-width: $breakpoint) {
                @content;
            }
        }
    }
}

@mixin typography($font-size, $large-font-size: 16px, $font-weight: 400, $large-font-weight: 400, $breakpoint: md) {

    @if map-has-key($typography, $font-size) {
        $typography-values: map-get($typography, $font-size);
        $size: map-get($typography-values, size-small);
        $size-large: map-get($typography-values, size-large);
        $weight: map-get($typography-values, weight);

        font-size: $size;
        font-weight: $weight;

        @if map-has-key($typography-values, line-height-small) {
            line-height: map-get($typography-values, line-height-small);
        }

        @include breakpoint($breakpoint) {
            font-size: $size-large;
            @if map-has-key($typography-values, line-height-large) {
                line-height: map-get($typography-values, line-height-large);
            }
        }
    } @else {
        font-size: $font-size;
        font-weight: $font-weight;

        @include breakpoint($breakpoint) {
            font-size: $large-font-size;
            font-weight: $large-font-weight;
        }
    }
}

@mixin IE() {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

@mixin map-to-props($map) {
    @if type-of($map) == map {
        @each $prop, $value in $map {
            @if type-of($value) != map {
                @if inspect($prop) == "description" {
                    /* #{inspect($value)} */
                } @else {
                    #{inspect($prop)}: #{inspect($value)};
                }
            } @else {
                @include map-to-props($value);
            }
        }
    }
}

@mixin animation($keyframe: none, $duration: 0s, $timing: ease) {
    animation: $keyframe $duration $timing;
}

@mixin clamp($lines: 2, $line-height: false ) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @if $line-height {
        line-height: #{$line-height}px;
        max-height: #{$lines * $line-height}px;
    }
}

@mixin recolorSVG($color: #000, $opacity: 1) {
    $r: calc(red($color) / 255);
    $g: calc(green($color) / 255);
    $b: calc(blue($color) / 255);
    $a: $opacity;

    // grayscale fallback if SVG from data url is not supported
    $lightness: lightness($color);

    filter: saturate(0%) brightness(0%) invert($lightness) opacity($opacity);

    // color filter
    $svg-filter-id: "recolor";

    filter:
        url(
            'data:image/svg+xml;utf8,\
    <svg xmlns="http://www.w3.org/2000/svg">\
      <filter id="#{$svg-filter-id}" color-interpolation-filters="sRGB">\
        <feColorMatrix type="matrix" values="\
          0 0 0 0 #{$r}\
          0 0 0 0 #{$g}\
          0 0 0 0 #{$b}\
          0 0 0 #{$a} 0\
        "/>\
      </filter>\
    </svg>\
    ##{$svg-filter-id}'
        );
}
